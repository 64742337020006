import { createContext, useState } from "react";
import { Outlet, useLocation } from "@remix-run/react";
import { Sidebar, MiniSidebar } from "../layouts";
import { Toaster } from "sonner";

export type LayoutContext = [() => void, boolean, string];
export type LayoutContextArgs = {
  toggleSidebar: () => void;
  isSidebarHidden: boolean;
  breadcrumb: string;
};

function createContextValues({
  toggleSidebar,
  isSidebarHidden,
  breadcrumb,
}: LayoutContextArgs): LayoutContext {
  return [toggleSidebar, isSidebarHidden, breadcrumb];
}

const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const toggleSidebar = () => setIsSidebarHidden(!isSidebarHidden);

  const fileNames = location?.pathname.split("/");
  const breadcrumb = fileNames.length > 2 ? fileNames[1] : "";

  return (
    <>
      <div className="dashboard">
        {isSidebarHidden ? <MiniSidebar /> : <Sidebar />}
        <div
          className={`dashboard-page basic-transition ${
            isSidebarHidden ? "dashboard-hide-sidebar" : ``
          }`}
        >
          <Outlet
            context={createContextValues({
              toggleSidebar,
              isSidebarHidden,
              breadcrumb,
            })}
          />
          {children}
        </div>
      </div>
      <Toaster position="top-center" expand={true} richColors />
    </>
  );
};

export default MainLayout;
