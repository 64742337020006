import React, { Component } from "react";
import "./DropDownCheckBox.css?__remix_sideEffect__";
import _ from "lodash";
import { withStyles } from 'tss-react/mui';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./CheckBoxOption.css?__remix_sideEffect__";

import styles from "./CheckBoxOption.css";
import dropDownCheckBoxStyles from "./DropDownCheckBox.css";

const itemsStates = ["checked", "indeterminate", "notChecked"];
const KptnColor = withStyles(
  (props) => <Checkbox color="default" {...props} />,
  (_theme, _params, classes) => ({
    root: {
      color: "rgba(54, 52, 49, 0.5)",
      [`&.${classes.checked}`]: {
        color: "#f45e45"
      }
    },

    checked: {
      color: "#f45e45"
    },
    indeterminate: {
      color: "#f45e45"
    }
  })
);
class dropDownCheckBox extends Component {
  constructor(props) {
    const includes = _.get(props.values, `${props.dropDownLabel}.includes`, []);
    const excludes = _.get(props.values, `${props.dropDownLabel}.excludes`, []);
    let mylistState = {};
    includes.forEach((i) => {
      mylistState = {
        ...mylistState,
        [i]: "checked"
      };
    });
    excludes.forEach((i) => {
      mylistState = {
        ...mylistState,
        [i]: "indeterminate"
      };
    });
    super(props);
    this.state = {
      ...this.props,
      items: this.props.items || [],
      values: this.props.values || {},
      showItems: false,
      activeBorder: false,
      resetState: this.props.resetState,

      listState: mylistState
    };
  }

  wrapperClickHandler = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.setState({ showItems: false });
  };

  static getDerivedStateFromProps(props, state) {
    const includes = _.get(props.values, `${props.dropDownLabel}.includes`, []);
    const excludes = _.get(props.values, `${props.dropDownLabel}.excludes`, []);

    if (
    !_.isEqual(state.values, props.values) ||
    includes.length > 0 ||
    excludes.length > 0)
    {
      if (includes.length > 0 || excludes.length > 0) {
        return {
          activeBorder: true,
          values: props.values
        };
      } else {
        return {
          activeBorder: false,
          listState: {},
          values: props.values
        };
      }
    } else {
      return null;
    }
  }

  arrowClick = () => {
    this.setState(
      {
        showItems: !this.state.showItems
      },
      () => {
        if (this.state.showItems) {
          document.addEventListener(
            "mousedown",
            this.wrapperClickHandler,
            false
          );
        } else {
          document.removeEventListener(
            "mousedown",
            this.wrapperClickHandler,
            false
          );
        }
      }
    );
  };

  getNextState = (currentState) => {
    let stateIndex = !!currentState ? itemsStates.indexOf(currentState) : -1;
    if (stateIndex === itemsStates.length - 1) {
      stateIndex = -1;
    }
    return itemsStates[stateIndex + 1];
  };

  filterHandler = (event) => {
    const value = event.target.value;
    this.setState((prevState) => {
      const stateOfItem = this.getNextState(prevState.listState[value]);
      const listState = {
        ...prevState.listState,
        [value]: stateOfItem
      };
      prevState.listState = listState;
      return prevState;
    });
  };

  closeApply = () => {
    const values = this.state.listState;
    const returnValue = {
      includes: [],
      excludes: []
    };
    for (const [key, value] of Object.entries(values)) {
      switch (value) {
        case itemsStates[0]:
          returnValue.includes.push(key);
          break;
        case itemsStates[1]:
          returnValue.excludes.push(key);
          break;
        default:
          break;
      }
    }

    this.arrowClick();
    this.props.filterHandler(this.props.dropDownLabel, returnValue);
  };

  render() {
    return (
      <div ref={(node) => this.node = node}>
        <div className="select-box-box" style={{ zIndex: this.props.zIndex }}>
          <div
            className="select-box-container"
            style={{
              border:
              this.state.activeBorder && !this.state.resetState ?
              "1px solid #f45e45" :
              "1px solid rgb(54, 52, 49, 0.2)"
            }}>

            <div className="select-box-selected-item" onClick={this.arrowClick}>
              {this.props.dropDownLabel}
            </div>
            <div className="select-box-arrow" onClick={this.arrowClick}>
              <span
                className={
                this.state.showItems ?
                "select-box-arrow-up" :
                "select-box-arrow-down"
                }>
              </span>
            </div>
            <div
              style={{ display: this.state.showItems ? "block" : "none" }}
              className="select-box-items pb-3">

              <div>
                {this.state.items.map((item) => {
                  return (
                    <FormGroup row key={item.value}>
                      <FormControlLabel
                        key={item.value}
                        control={
                        <KptnColor
                          key={item.value}
                          name={item.value}
                          onChange={this.filterHandler}
                          value={item.id}
                          checked={
                          this.state.listState[item.id] === "checked"
                          }
                          indeterminate={
                          this.state.listState[item.id] === "indeterminate"
                          }
                          disableRipple />

                        }
                        label={item.value} />

                    </FormGroup>);

                })}
              </div>
              <div>
                <button
                  style={{ minWidth: "130px" }}
                  type="submit"
                  onClick={this.closeApply}>

                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>);

  }
}

export default dropDownCheckBox;

export const links = () => [
{ rel: "stylesheet", href: styles },
{ rel: "stylesheet", href: dropDownCheckBoxStyles }];